<template>
  <div id="inspectorId">
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm bệnh nghề nghiệp'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="downloadExampleFile"
        @clickExportExcel="downloadExportFile"
        @importFile="importFileExcel($event)"
        @clickAdd="showModalCreate"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        :columns="columns"
        :rows="dataTable || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Chức năng -->
          <span v-if="props.column.field == 'action'">
            <span>
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="Edit3Icon"
                size="18"
                class="text-body"
                @click="showModalEdit(props.row.id)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body ml-2"
                @click="deleteItem(props.row.id)"
              />
            </span>

          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <edit-occupational-disease
        :dataOccupationalDisease="dataOccupationalDisease"
        :type="modalType"
        @handleCreateDisease="handleCreateDisease"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import EditOccupationalDisease from './components/EditOccupationalDisease.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    EditOccupationalDisease,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      totalRecord: 0,
      dataTable: [],
      columns: [
        {
          label: 'MÃ BỆNH',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN BỆNH NGHỀ NGHIỆP',
          field: 'name',
          sortable: false,
        },
        {
          label: 'YẾU TỐ CÓ HẠI',
          field: 'element',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      showBtnMultiDelete: false,
      modalType: '',
      diseaseId: '',
      dataOccupationalDisease: {},
      arrayExcel: [
        'code',
        'name',
        'element',
      ],
    }
  },
  created() {
    this.fetchDataOccupationalDisease(this.urlQuery)
  },
  methods: {

    ...mapActions('disease', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),

    // Lấy danh sách bệnh nghề nghiệp
    async fetchDataOccupationalDisease(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_OCCUPATIONAL_DISEASE, {
        params: urlQuery,
      })
      this.dataTable = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.$hideLoading()
    },

    async handleCreateDisease(val) {
      if (this.modalType === 'edit') {
        const payload = {
          ...val,
          id: this.diseaseId,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_OCCUPATIONAL_DISEASE, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Sửa thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide('edit-occupational-disease')
          }
          this.fetchDataOccupationalDisease(this.urlQuery)
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
      if (this.modalType === 'add') {
        const payload = {
          ...val,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_OCCUPATIONAL_DISEASE, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Thêm mới thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide('edit-occupational-disease')
          }
          this.fetchDataOccupationalDisease(this.urlQuery)
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataOccupationalDisease(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDataOccupationalDisease(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDataOccupationalDisease(this.urlQuery)
      }
    },

    // Mở modal tạo mới bệnh nghề nghiệp
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show('edit-occupational-disease')
    },

    // Mở modal chỉnh sửa bệnh nghề nghiệp
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.diseaseId = id
      this.$bvModal.show('edit-occupational-disease')
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_OCCUPATIONAL_DISEASE}${this.diseaseId}`)
      this.dataOccupationalDisease = data
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_OCCUPATIONAL_DISEASE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataOccupationalDisease(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-occupational-disease' })
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
