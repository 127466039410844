<template>
  <b-modal
    id="edit-occupational-disease"
    size="md"
    :title="type==='add' ?'Thêm bệnh nghề nghiệp' : 'Chỉnh sửa bệnh nghề nghiệp'"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateDisease"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Mã bệnh nghề nghiệp <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customCode"
        >
          <b-form-input
            id="InputHelp"
            v-model="dataDisease.code"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Tên bệnh nghề nghiệp <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customNameDisease"
        >
          <b-form-input
            id="InputHelp"
            v-model="dataDisease.name"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        class="mt-1"
        :label="'Yếu tố gây hại'"
        label-for="element"
      >
        <b-form-input v-model="dataDisease.element" />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dataOccupationalDisease: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataDisease: {
        code: '',
        name: '',
        element: '',
      },
      customNameDisease: {
        required: 'Tên bệnh nghề nghiệp là bắt buộc',
      },
      customCode: {
        required: 'Mã bệnh nghề nghiệp là bắt buộc',
      },
    }
  },
  watch: {
    dataOccupationalDisease(val) {
      if (val && this.type === 'edit') {
        this.dataDisease = {
          code: this.dataOccupationalDisease.code,
          name: this.dataOccupationalDisease.name,
          element: this.dataOccupationalDisease.element,
        }
      }
    },
  },
  methods: {
    handleCreateDisease(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateDisease', this.dataDisease)
        }
      })
    },
    resetState() {
      this.dataDisease = {
        code: '',
        name: '',
        element: '',
      }
    },
  },
}
</script>
